export default {

      'center.type.1' : 'Buddhist Center'
    , 'center.type.7' : 'Buddhist Center'
    , 'center.type.2' : 'Buddhist Group'
    , 'center.type.16' : 'Buddhist Group'
    , 'center.type.3': 'Buddhist Retreatcenter'
    , 'center.type.6' : 'Buddhist Retreatcenter'
    , 'center.type.4' : 'Buddhist Contact'

    , 'center.contact.private': 'Please contact the center directly for the address.'

    , 'global.more' : 'more'
    , 'global.less' : 'less'

    , 'country.long.AF' : 'Afghanistan'
    , 'country.long.AX' : 'Aland Islands'
    , 'country.long.AL' : 'Albania'
    , 'country.long.DZ' : 'Algeria'
    , 'country.long.AS' : 'American Samoa'
    , 'country.long.AD' : 'Andorra'
    , 'country.long.AO' : 'Angola'
    , 'country.long.AI' : 'Anguilla'
    , 'country.long.AQ' : 'Antarctica'
    , 'country.long.AG' : 'Antigua and Barbuda'
    , 'country.long.AR' : 'Argentina'
    , 'country.long.AM' : 'Armenia'
    , 'country.long.AW' : 'Aruba'
    , 'country.long.AU' : 'Australia'
    , 'country.long.AT' : 'Austria'
    , 'country.long.AZ' : 'Azerbaijan'
    , 'country.long.BS' : 'Bahamas'
    , 'country.long.BH' : 'Bahrain'
    , 'country.long.BD' : 'Bangladesh'
    , 'country.long.BB' : 'Barbados'
    , 'country.long.BY' : 'Belarus'
    , 'country.long.BE' : 'Belgium'
    , 'country.long.BZ' : 'Belize'
    , 'country.long.BJ' : 'Benin'
    , 'country.long.BM' : 'Bermuda'
    , 'country.long.BT' : 'Bhutan'
    , 'country.long.BO' : 'Bolivia'
    , 'country.long.BA' : 'Bosnia and Herzegovina'
    , 'country.long.BW' : 'Botswana'
    , 'country.long.BV' : 'Bouvet Island'
    , 'country.long.BR' : 'Brazil'
    , 'country.long.IO' : 'British Indian Ocean Territory'
    , 'country.long.VG' : 'British Virgin Islands'
    , 'country.long.BN' : 'Brunei Darussalam'
    , 'country.long.BG' : 'Bulgaria'
    , 'country.long.BF' : 'Burkina Faso'
    , 'country.long.BI' : 'Burundi'
    , 'country.long.KH' : 'Cambodia'
    , 'country.long.CM' : 'Cameroon'
    , 'country.long.CA' : 'Canada'
    , 'country.long.CV' : 'Cape Verde'
    , 'country.long.KY' : 'Cayman Islands '
    , 'country.long.CF' : 'Central African Republic'
    , 'country.long.TD' : 'Chad'
    , 'country.long.CL' : 'Chile'
    , 'country.long.CN' : 'China'
    , 'country.long.CX' : 'Christmas Island'
    , 'country.long.CC' : 'Cocos (Keeling) Islands'
    , 'country.long.CO' : 'Colombia'
    , 'country.long.KM' : 'Comoros'
    , 'country.long.CG' : 'Congo (Brazzaville)'
    , 'country.long.CD' : 'Congo'
    , 'country.long.CK' : 'Cook Islands '
    , 'country.long.CR' : 'Costa Rica'
    , 'country.long.HR' : 'Croatia'
    , 'country.long.CU' : 'Cuba'
    , 'country.long.CY' : 'Cyprus'
    , 'country.long.CZ' : 'Czech Republic'
    , 'country.long.CI' : 'Côte d\'Ivoire'
    , 'country.long.DK' : 'Denmark'
    , 'country.long.DJ' : 'Djibouti'
    , 'country.long.DM' : 'Dominica'
    , 'country.long.DO' : 'Dominican Republic'
    , 'country.long.EC' : 'Ecuador'
    , 'country.long.EG' : 'Egypt'
    , 'country.long.SV' : 'El Salvador'
    , 'country.long.GQ' : 'Equatorial Guinea'
    , 'country.long.ER' : 'Eritrea'
    , 'country.long.EE' : 'Estonia'
    , 'country.long.ET' : 'Ethiopia'
    , 'country.long.FK' : 'Falkland Islands (Malvinas)'
    , 'country.long.FO' : 'Faroe Islands'
    , 'country.long.FJ' : 'Fiji'
    , 'country.long.FI' : 'Finland'
    , 'country.long.FR' : 'France'
    , 'country.long.GF' : 'French Guiana'
    , 'country.long.PF' : 'French Polynesia'
    , 'country.long.TF' : 'French Southern Territories'
    , 'country.long.GA' : 'Gabon'
    , 'country.long.GM' : 'Gambia'
    , 'country.long.GE' : 'Georgia'
    , 'country.long.DE' : 'Germany'
    , 'country.long.GH' : 'Ghana'
    , 'country.long.GI' : 'Gibraltar '
    , 'country.long.GR' : 'Greece'
    , 'country.long.GL' : 'Greenland'
    , 'country.long.GD' : 'Grenada'
    , 'country.long.GP' : 'Guadeloupe'
    , 'country.long.GU' : 'Guam'
    , 'country.long.GT' : 'Guatemala'
    , 'country.long.GG' : 'Guernsey'
    , 'country.long.GN' : 'Guinea'
    , 'country.long.GW' : 'Guinea-Bissau'
    , 'country.long.GY' : 'Guyana'
    , 'country.long.HT' : 'Haiti'
    , 'country.long.HM' : 'Heard Island and Mcdonald Islands'
    , 'country.long.VA' : 'Holy See (Vatican City State)'
    , 'country.long.HN' : 'Honduras'
    , 'country.long.HK' : 'Hong Kong'
    , 'country.long.HU' : 'Hungary'
    , 'country.long.IS' : 'Iceland'
    , 'country.long.IN' : 'India'
    , 'country.long.ID' : 'Indonesia'
    , 'country.long.IR' : 'Iran'
    , 'country.long.IQ' : 'Iraq'
    , 'country.long.IE' : 'Ireland'
    , 'country.long.IM' : 'Isle of Man '
    , 'country.long.IL' : 'Israel'
    , 'country.long.IT' : 'Italy'
    , 'country.long.JM' : 'Jamaica'
    , 'country.long.JP' : 'Japan'
    , 'country.long.JE' : 'Jersey'
    , 'country.long.JO' : 'Jordan'
    , 'country.long.KZ' : 'Kazakhstan'
    , 'country.long.KE' : 'Kenya'
    , 'country.long.KI' : 'Kiribati'
    , 'country.long.KP' : 'North Korea'
    , 'country.long.KR' : 'South Korea'
    , 'country.long.KW' : 'Kuwait'
    , 'country.long.KG' : 'Kyrgyzstan'
    , 'country.long.LA' : 'Lao PDR'
    , 'country.long.LV' : 'Latvia'
    , 'country.long.LB' : 'Lebanon'
    , 'country.long.LS' : 'Lesotho'
    , 'country.long.LR' : 'Liberia'
    , 'country.long.LY' : 'Libya'
    , 'country.long.LI' : 'Liechtenstein'
    , 'country.long.LT' : 'Lithuania'
    , 'country.long.LU' : 'Luxembourg'
    , 'country.long.MO' : 'Macao'
    , 'country.long.MK' : 'Macedonia'
    , 'country.long.MG' : 'Madagascar'
    , 'country.long.MW' : 'Malawi'
    , 'country.long.MY' : 'Malaysia'
    , 'country.long.MV' : 'Maldives'
    , 'country.long.ML' : 'Mali'
    , 'country.long.MT' : 'Malta'
    , 'country.long.MH' : 'Marshall Islands'
    , 'country.long.MQ' : 'Martinique'
    , 'country.long.MR' : 'Mauritania'
    , 'country.long.MU' : 'Mauritius'
    , 'country.long.YT' : 'Mayotte'
    , 'country.long.MX' : 'Mexico'
    , 'country.long.FM' : 'Micronesia'
    , 'country.long.MD' : 'Moldova'
    , 'country.long.MC' : 'Monaco'
    , 'country.long.MN' : 'Mongolia'
    , 'country.long.ME' : 'Montenegro'
    , 'country.long.MS' : 'Montserrat'
    , 'country.long.MA' : 'Morocco'
    , 'country.long.MZ' : 'Mozambique'
    , 'country.long.MM' : 'Myanmar'
    , 'country.long.NA' : 'Namibia'
    , 'country.long.NR' : 'Nauru'
    , 'country.long.NP' : 'Nepal'
    , 'country.long.NL' : 'Netherlands'
    , 'country.long.AN' : 'Netherlands Antilles'
    , 'country.long.NC' : 'New Caledonia'
    , 'country.long.NZ' : 'New Zealand'
    , 'country.long.NI' : 'Nicaragua'
    , 'country.long.NE' : 'Niger'
    , 'country.long.NG' : 'Nigeria'
    , 'country.long.NU' : 'Niue '
    , 'country.long.NF' : 'Norfolk Island'
    , 'country.long.MP' : 'Northern Mariana Islands'
    , 'country.long.NO' : 'Norway'
    , 'country.long.OM' : 'Oman'
    , 'country.long.PK' : 'Pakistan'
    , 'country.long.PW' : 'Palau'
    , 'country.long.PA' : 'Panama'
    , 'country.long.PG' : 'Papua New Guinea'
    , 'country.long.PY' : 'Paraguay'
    , 'country.long.PE' : 'Peru'
    , 'country.long.PH' : 'Philippines'
    , 'country.long.PN' : 'Pitcairn'
    , 'country.long.PL' : 'Poland'
    , 'country.long.PT' : 'Portugal'
    , 'country.long.PR' : 'Puerto Rico'
    , 'country.long.QA' : 'Qatar'
    , 'country.long.RO' : 'Romania'
    , 'country.long.RU' : 'Russian Federation'
    , 'country.long.RW' : 'Rwanda'
    , 'country.long.RE' : 'Réunion'
    , 'country.long.SH' : 'Saint Helena'
    , 'country.long.KN' : 'Saint Kitts and Nevis'
    , 'country.long.LC' : 'Saint Lucia'
    , 'country.long.PM' : 'Saint Pierre and Miquelon '
    , 'country.long.VC' : 'Saint Vincent and Grenadines'
    , 'country.long.BL' : 'Saint-Barthélemy'
    , 'country.long.MF' : 'Saint-Martin (French part)'
    , 'country.long.WS' : 'Samoa'
    , 'country.long.SM' : 'San Marino'
    , 'country.long.ST' : 'Sao Tome and Principe'
    , 'country.long.SA' : 'Saudi Arabia'
    , 'country.long.SN' : 'Senegal'
    , 'country.long.RS' : 'Serbia'
    , 'country.long.SC' : 'Seychelles'
    , 'country.long.SL' : 'Sierra Leone'
    , 'country.long.SG' : 'Singapore'
    , 'country.long.SK' : 'Slovakia'
    , 'country.long.SI' : 'Slovenia'
    , 'country.long.SB' : 'Solomon Islands'
    , 'country.long.SO' : 'Somalia'
    , 'country.long.ZA' : 'South Africa'
    , 'country.long.GS' : 'South Georgia and the South Sandwich Islands'
    , 'country.long.SS' : 'South Sudan'
    , 'country.long.ES' : 'Spain'
    , 'country.long.LK' : 'Sri Lanka'
    , 'country.long.SD' : 'Sudan'
    , 'country.long.SR' : 'Suriname'
    , 'country.long.SJ' : 'Svalbard and Jan Mayen Islands'
    , 'country.long.SZ' : 'Swaziland'
    , 'country.long.SE' : 'Sweden'
    , 'country.long.CH' : 'Switzerland'
    , 'country.long.SY' : 'Syrian Arab Republic (Syria)'
    , 'country.long.TW' : 'Taiwan, Republic of China'
    , 'country.long.TJ' : 'Tajikistan'
    , 'country.long.TZ' : 'Tanzania'
    , 'country.long.TH' : 'Thailand'
    , 'country.long.TL' : 'Timor-Leste'
    , 'country.long.TG' : 'Togo'
    , 'country.long.TK' : 'Tokelau '
    , 'country.long.TO' : 'Tonga'
    , 'country.long.TT' : 'Trinidad and Tobago'
    , 'country.long.TN' : 'Tunisia'
    , 'country.long.TR' : 'Turkey'
    , 'country.long.TM' : 'Turkmenistan'
    , 'country.long.TC' : 'Turks and Caicos Islands '
    , 'country.long.TV' : 'Tuvalu'
    , 'country.long.UG' : 'Uganda'
    , 'country.long.UA' : 'Ukraine'
    , 'country.long.AE' : 'United Arab Emirates'
    , 'country.long.GB' : 'United Kingdom'
    , 'country.long.UM' : 'United States Minor Outlying Islands'
    , 'country.long.US' : 'United States of America'
    , 'country.long.UY' : 'Uruguay'
    , 'country.long.UZ' : 'Uzbekistan'
    , 'country.long.VU' : 'Vanuatu'
    , 'country.long.VE' : 'Venezuela'
    , 'country.long.VN' : 'Viet Nam'
    , 'country.long.VI' : 'Virgin Islands, US'
    , 'country.long.WF' : 'Wallis and Futuna Islands'
    , 'country.long.EH' : 'Western Sahara'
    , 'country.long.YE' : 'Yemen'
    , 'country.long.ZM' : 'Zambia'
    , 'country.long.ZW' : 'Zimbabwe'

};
