import $ from '../utils/jquery-global';
import Widgets from 'js-widget-hooks';
import trans from '../utils/translator';


Widgets.register('show-more', function(el){
    let elem = $(el);
    let detail = elem.find('.event-line__details');
    let theLink = elem.find('.target--more');

    elem.click(function(){
        if (detail.hasClass('show')) {
            detail.removeClass('show');
            theLink.html(trans.t('global.more') + '...');
        } else {
            detail.addClass('show');
            theLink.html(trans.t('global.less') + '...');
        }
    })
});