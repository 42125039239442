import $ from '../utils/jquery-global';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from '../view/components/Modal.jsx';

export default {

    createModal : function(id, content, size, title) {

        let wrapperId = id + '-wrapper',
            wEl = $('#' + wrapperId),
            modal
            ;

        if(! wEl.length) {
            wEl = $('<div id="' + wEl + '"/>');
            $('body').append(wEl);
            modal = ReactDOM.render(<Modal id={id} title={title} size={size}>{content}</Modal>, wEl[0]);
            wEl.data('modal', modal);
        } else {
            modal = wEl.data('modal');
        }

        return modal;
    }

}