import $ from '../utils/jquery-global';
import React from 'react';
import URL from 'js-handy-url';
import ModalWidget from './ModalWidget.jsx';
import Gallery from '../view/Gallery.jsx';
import app from '../components/app';

class GalleryWidget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentId : props.initial_id,
            content: $('#' + props.initial_id).html()
        };

        this.images = null;
    }

    loadContentById (id) {
        if (this.state.currentId !== id) {

            var root = $('#' + id);

            app.setState( {'modal.title' : root.find('.block__h2').text()} );
            this.setState( { content : root.find('.content-wrapper').html(), currentId : id } )
        }

        return this.state.content;
    }

    render () {
        return <Gallery
            content={this.state.content}
            items={this.props.items}
            activeId={this.state.currentId}

            handle_loadContentById={this.loadContentById.bind(this)}
            />;
    }
}

export default {
    init: function(elem){
        let item_array = [],
            items = Array.from(elem.querySelectorAll('.image-area__image')).map(function(){

                let el = $(this);

                item_array.push({
                    src : el.attr('src'),
                    id : new URL( el.closest('a').attr('href') ).hash,
                    className: el.attr('class'),
                    title: el.closest('div').find('.block__h2').text()
                });
            }),
            modal = ModalWidget.createModal('gallery', <GalleryWidget items={ item_array } />, 'large'),
            gallery = modal.refs[0]
            ;

        $(elem).find('.image-area__item').click(function(){

            gallery.loadContentById(new URL( $(this).attr('href') ).hash);
            modal.show();
        });
    }
}
