import $ from '../utils/jquery-global';
import Widgets from 'js-widget-hooks';

// the side slide navigation widget
Widgets.register('side-slide', function(el){
    let elem = $(el);
    let target = jQuery(elem.data('target'));
    let overlay = $('<div class="navbar-slide-overlay"/>');
    $('body').append(overlay);

    if (target.length) {

        let actives = elem.add(overlay);

        actives.bind('click', { target: target}, function(e) {
            let inClass = 'in',
                outClass = 'out',
                width,
                target = e.data.target
            ;

            if (target.hasClass(inClass)) {
                overlay.addClass(outClass).removeClass(inClass);
                width = parseFloat(target.css('width')) / parseFloat(target.parent().css('width'));
                target.stop().animate({left: -Math.round(100 * width) + '%'}, 300, function(){
                    target.removeClass(inClass);
                    overlay.removeClass(outClass);
                });
            } else {
                target.addClass(inClass);
                overlay.addClass(inClass);
                target.stop().animate({left: 0}, 300);
            }
        });
    }
});