import $ from '../utils/jquery-global';
import Widgets from 'js-widget-hooks';
import 'bootstrap/js/transition';
import 'bootstrap/js/collapse';
import 'bootstrap/js/carousel';

Widgets.register('carousel', function(elem){
    let interval = 3500;

    $.fn.carousel.Constructor.TRANSITION_DURATION = interval - 500;

    $(elem).carousel({
        interval : interval
    });
});