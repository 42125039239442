import React from 'react';

export default function Gallery (props) {
    var handle_loadContentById = props.handle_loadContentById,
        activeId = props.activeId,
        gallery_switches = props.items.map(function(item){
            return <div key={item.id} className="p col-xs-4 col-sm-12"><img className={item.className + (item.id == activeId ? ' image-area__image--selected' : '')} src={item.src} title={item.title} onClick={ handle_loadContentById.bind(null, item.id) } /></div>
        })
    ;

    return <div className="row">
        <div className="col-sm-10" dangerouslySetInnerHTML={{__html: props.content}}>

        </div>
        <div className="col-sm-2 gallery__sidebar">
            <div className="row">
                {gallery_switches}
            </div>
        </div>
    </div>
}
