// uses GLOBAL_CONFIG.matomo_site_id

// matomo trackimg code

if (GLOBAL_CONFIG.matomo_site_id) {

    if (['local', 'localhost', '1'].indexOf(window.location.hostname.split('.').pop()) > -1) {
        console.warn('Tracking works with site id %o, but is dissabled on localhost.', GLOBAL_CONFIG.matomo_site_id);
    } else {
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(["disableCookies"]);
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="//stats.dwbn.org/";
            _paq.push(['setTrackerUrl', u+'js/index.php']);
            _paq.push(['setSiteId', GLOBAL_CONFIG.matomo_site_id]);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'js/index.php'; s.parentNode.insertBefore(g,s);
        })();
    }
} else {
    console.warn('Analytics are disabled due to missing site_id.')
}