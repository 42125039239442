'use strict';

/**
 * Created by Andreas on 30/04/16.
 *
 * This small module handles state changes through components, and also is in charge of data communication between components
 */

var subscribers = {},
    state,
    mod = 'x',

    appStateIdent = 'dwbn_app_state',

    app = {

        supports: {
            localStorage: null
        },

        /**
         * Allows a component to subscribe with a callback function to a state change
         * @param stateAttrs    Array or String of subscribed state
         * @param callback      A Function, which is called on state change
         */
        subscribe : function(stateAttrs, callback) {
            var eventName, i;

            if (typeof stateAttrs != 'object') stateAttrs = [stateAttrs];

            for (i = 0; i < stateAttrs.length; i++) {
                eventName = stateAttrs[i];
                if (!subscribers[eventName]) subscribers[eventName] = [];
                subscribers[eventName].push(callback);
            }
        },


        /**
         * Returns either the whole state object or just the required part of it
         * @param param
         * @returns {*}
         */
        getState : function(param){
            return param ? state[param] : state;
        },


        /**
         * triggers an event
         * @param data          The data information - will be the updated state {key: value}
         * @param originator    The originator of the event
         */
        setState: function(data, originator) {
            for(let attr in data){
                // only accept real state changes
                if (state[attr] !== data[attr]) {
                    state[attr] = data[attr];

                    console.log('APP STATE: set "%s" to %o', attr, data[attr]);

                    if(subscribers[attr]){
                        for (var i = 0; i < subscribers[attr].length; i++){
                            var callback = subscribers[attr][i];
                            callback(state[attr], originator);
                        }
                    }

                    // persist the changes to local storage to get them back
                    persistState();
                }
            }
        }
    },

    persistState = function(){
        if (app.supports.localStorage) {
            localStorage.setItem(appStateIdent, JSON.stringify(state));
        } else {
            console.error('Local Storage is not available - some functionality might not work.');
        }
    }
;

// modernizers approach for localStorage
// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    app.supports.localStorage = true;
} catch(e) {
    app.supports.localStorage = false;
}

// read the state
if (app.supports.localStorage) {
    state = JSON.parse(localStorage.getItem(appStateIdent));
} else {
    console.error('Local Storage is not available - some functionality might not work.');
}

// the reset
if (state === null) {
    state = {};
}


export default app;
