import $ from 'jquery';
import React from 'react';
import app from '../../components/app';
import 'bootstrap/js/modal';


export default class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title
        }
    }

    show () {
        $('#' + this.props.id).modal('show');

        // $(ReactDOM.findDOMNode(this)).css('opacity', 0).show().scrollTop(0).animate({opacity: 1}, 500);
    }

    hide () {
        $('#' + this.props.id).modal('hide');
    }

    componentWillMount () {
        var that = this;
        app.subscribe('modal.title', function(title){
            that.setState({title: title});
        });
    }

    render () {
        var classes = ['modal-dialog'];
        if (this.props.size === 'large') classes.push('modal-lg');

        var head = null;

        if ( this.state.title ) {
            head = <div className="modal-header">
                <button type="button" className="close icon icon-close" data-dismiss="modal">&times;</button>
                <h2 className="block__h2">{ this.state.title }</h2>
            </div>
        }

        return <div id={this.props.id} className="modal fade">
            <div className={ classes.join(' ') }>
                <div className="modal-content">
                    { head }
                    <div className="modal-body" id={ this.props.id + '-content' }>
                        { React.Children.map(this.props.children, (element, idx) => {
                            return React.cloneElement(element, { ref: idx });
                        })}
                    </div>
                </div>
            </div>
        </div>;
    }

}