import $ from '../../../../../src/Resources/core/jsx/utils/jquery-global';
import Widgets from 'js-widget-hooks';

const consentKey = "bs-gdpr-video-consent";

const addVideo = function (el, src) {
    el.empty();
    el.append(`<iframe class="embed-responsive-item" src="${src}" allowFullScreen></iframe>`);
}

Widgets.register('gdpr-video', function(elem){
    const el = $(elem);
    const src = el.data('src');
    const id = 'gdpr-' + Math.round(Math.random() * 1000);

    if (window.localStorage.getItem(consentKey)) {
        addVideo(el, src);
    } else {
        const overlay = $(`<div class="embed-responsive-item embed-responsive-item--gdpr">
            <p><label class="embed-responsive-item__label"><input type="checkbox" class="gdpr-checkbox"> Videos von youtube.com anzeigen</label> und die <a href="https://policies.google.com/privacy?hl=de" target="_blank">Datenschutz Bestimmungen von Google</a> akzeptieren?</p>
            <p>Oder: <a href="${src}" target="_blank">Video direkt auf youtube.com ansehen</a></p>
        </div>`)

        overlay.find('.gdpr-checkbox').click(function () {
            window.localStorage.setItem(consentKey, '1');
            addVideo(el, src);
        })

        el.append(overlay);
    }

});

//