'use strict';

import en from '../nls/default/translations';
import de from '../nls/de/translations';
import '../polyfill/string.format';


var defaultLocale = 'en',
    locales = ['en', 'de', 'ru', 'pl'],
    currentLocale = defaultLocale,

    keys = {
        en : en,
        de : de
    },

    /**
     *
     * @param {string} key
     * @returns {string}
     */
    getNotFoundString = function getNotFoundString(key) {
        return '[missing: ' + key + ']';
    }
;


export default {

    /**
     * the root translation function
     * @param {string} key
     * @returns {string}
     */
    t: function t(key) {
        var translation = keys[currentLocale][key];
        if (translation === undefined && currentLocale !== defaultLocale ) {
            translation = keys[defaultLocale][key]
        }

        return translation !== undefined ? translation : getNotFoundString(key);
    },

    /**
     * format is key, param1, param2, ...
     */
    tf: function tf() {
        var args = Array.prototype.slice.call(arguments),
            key = args.shift(),
            trans = this.t(key);


        return trans.format.apply(trans, args);
    },


    // set a locale
    setLocale: function setLocale(locale) {
        currentLocale = locale;
    },


    getDefaultLocale: function getDefaultLocale() {return defaultLocale;},
    getAvailableLocales: function getAvailableLocales() {return locales;},
    getCurrentLocale: function getCurrentLocale() {return currentLocale ? currentLocale : this.getDefaultLocale();},

    getTranslationValue: function getTranslationValue(value) {
        var locale = this.getCurrentLocale();

        if (typeof value == 'string') return value; // this line handles the database legacy for old entrys

        return value && value[locale] ? value[locale] : value ? value[this.getDefaultLocale()] : '';
    }
}

