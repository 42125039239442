import $ from '../utils/jquery-global';
import Widgets from 'js-widget-hooks';

Widgets.register('teaser', function(elem){
    $(elem).click(function(){
        var el = $(this),
            content = el.closest('.block').find('.content'),
            visible = content.is(':visible'),
            a = el.find('a')
        ;

        if (visible) {
            content.slideUp(300, function(){
                a.text('mehr lesen')
            });

        } else {
            content.slideDown(120, function(){
                a.text('weniger anzeigen')
            });

        }
    });
});